<template>
    <div class="content">
        <div style="padding:0 20px ;">
            <Header></Header>
        </div>
        <div class="header">


            <span class="title" @click="goBack">
                <i style="cursor: pointer;" class="el-icon-arrow-left"></i>声音克隆
            </span>
        </div>
        <div class="cloneBox">
            <div class="cloneBox_left">
                <div class="Boxtitle">形象克隆内容</div>
                <!-- 声音名称 -->
                <div class="cloneName">
                    <div class="title"><span>*</span>声音名称</div>
                    <div><el-input type="text" placeholder="请输入声音名称" v-model="cloneName" maxlength="8" show-word-limit>
                        </el-input></div>
                </div>
                <!-- 标签选择 -->
                <div class="selectTag">
                    <div class="title"><span>*</span>个性标签</div>
                    <div class="itemBox">
                        <div class="item" @click="tagActive(item, index)" :class="item.state == 1 ? 'active' : ''"
                            v-for="(item, index) in tagList" :key="index">
                            {{ item.tag }}
                        </div>
                    </div>
                </div>
                <div class="timbre">
                    <div class="title">性别选择</div>
                    <div class="radio"> <el-radio v-model="radio" label="1">男</el-radio>
                        <el-radio v-model="radio" label="2">女</el-radio>
                    </div>
                </div>

                <!-- 音频文件 -->
                <div class="audioFile">
                    <div class="audioFile_box">
                        <div class="left">
                            <div class="title"><span>*</span>音频文件</div>
                            <div class="uploadBox" v-if="!aPath && clonesNumber !== 0">
                                <div class="upload">
                                    <audioUpload @audioPath="audioPath" :minDuration="5" :maxDuration="300">
                                    </audioUpload>
                                </div>
                            </div>

                            <div class="playBox" v-if="aPath">
                                <div class="playModel">
                                    <audioPlayer :aPath="aPath" @audioPath="audioPath" />
                                </div>
                                <div class="anew_craft">
                                    <div class="anew" v-if="clonesNumber !== 0" @click="reupload"> 重新上传</div>
                                    <div v-if="clonesNumber == 0"></div>
                                    <div class="syntheticAudition">
                                        <div class="saBut" @click="syntheticAudition">合成试听 <span>（{{
                                            clonesNumber }}次）</span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="referenceText">
                                <div class="r_title">请参考右侧录制要求录制音频，请<span>查看录制文本参考</span> </div>
                                <div class="r_title">支持mp3,m4a,wav格式，音频要求不少于30秒，最长不超过5分钟</div>
                            </div>
                        </div>
                        <div class="right">
                            <div :class="index == selectAudioIndex ? 'auditionListClick' : 'auditionList'"
                                @click.stop="selectAudio(item, index)" v-for="(item, index) in auditionList"
                                :key="index">
                                <div>
                                    <audioPlayer :key="key" :aPath="item.listen_url" />
                                </div>
                                <div style="cursor: pointer;"><img @click.stop="refreshAudition(item, index)"
                                        src="../../../src/assets/shuaxin.png" alt=""></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cloneBox_right">
                <div class="right_title">录制建议</div>
                <div class="right_box">
                    <div class="top"><img src="../../assets/luzhijianyi.png" alt=""></div>
                    <div class="recordedText" v-for="(item, index) in recordedTextList" :key="index">
                        <div class="title">
                            {{ item.name }}
                        </div>
                        <div class="text" v-for="(ite, ind) in item.text" :key="ind">
                            {{ ite }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 提交 -->
        <div class="submit_box">

            <div class="submit_cancel">
                <div><el-button type="primary" @click="confirmSubmission">提 交</el-button></div>
                <div><el-button @click="cancel">取 消</el-button></div>
            </div>
            <div class="agreement">

                <el-checkbox v-model="signinChecked">我确认我拥有上传音频的所有必要权力，我保证不会用克隆音频创作违法内容，并且会严格遵守

                </el-checkbox><span @click.stop="openAgreement">《声音克隆服务协议》</span>
            </div>
        </div>

        <el-dialog title="声音克隆服务协议" custom-class="agreementShow" :visible.sync="agreementShow" width="600px">
            <div class="text">
                <SoundcloningProtocol />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="consentAgreement">阅读并同意</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Header from '@/components/Header.vue'
import audioUpload from '../../../components/audioUpload.vue';
import audioPlayer from '../../../components/AudioPlayer.vue'
import { getAvailable, syntheticAudition, refreshAudition, confirmAudio, getTagList } from '../../../api/api'
import { throttle } from '../../common/js/utils'
import SoundcloningProtocol from '../../../components/agreement/SoundCloning.vue'
export default {
    components: {
        Header,
        audioUpload,
        audioPlayer,
        SoundcloningProtocol
    },
    data() {
        return {
            cloneName: '',//音频名称
            signinChecked: false,
            // 录制建议
            recordedTextList: [
                {
                    name: '录制文本',
                    text: ['1.克隆声音会保留上传音频的语气风格，为避免语气违和，需 准备贴近最终使用场景的文本，如用于电商直播的声音使用。电商带货文本进行录制。'
                    ]
                },
                {
                    name: '录制环境',
                    text: [
                        '1.保障无环境背景噪声、无明显回声和混响、信噪比大于30dB。',
                        '2.录制期间话筒距离嘴部10cm-50cm之间，保持位置距离稳定。',
                        '3.录制过程中保持姿态稳定，减少人为噪声，如肢体碰撞声、 服装摩擦声、桌椅移动声、鼠标键盘声等。'
                    ]
                },
                {
                    name: '录制格式',
                    text: [
                        '1.音频格式限制mp3,m4a,wav，推荐无压缩wav格式。',
                        '2.推荐使用单声道，采样率24k以上，位深度16bit以上。'
                    ]
                },
            ],
            tagList: [],//标签列表
            aPath: '',//OSS返回的音频地址
            clonesNumber: '0',// 克隆次数
            radio: '0',//1男-2女
            tagArr: [],//选中的标签

            auditionList: [],//试听列表
            selectAudioIndex: '-1',

            key: 0,
            time: null,
            fullscreenLoading: false,//加载
            agreementShow: false,//协议弹框
        }
    },
    created: function () {
        // 获取克隆次数
        this.getAvailable()
        // 获取公共标签
        this.getTagList()

        window.myData = this;//将变量全局化,这样浏览器可以查看当前data是什么情况::myData._data
    },
    methods: {
        goBack() {
            this.$router.back()
        },
        audioPath(e) {
            this.aPath = e
            // console.log(this.aPath)
        },





        tagActive(item, index) {//标签选择
            let that = this
            let list = that.tagList
            let num = 0
            let tagArr = []
            if (item.state == 0) {
                for (let i = 0; i < list.length; i++) {
                    if (list[i].state == '1') {
                        num++
                    }
                    if (num == '3') {
                        that.$message.warning("最多选择三个标签");
                        return
                    }
                }
                this.tagArr.push(list[index].tag)
                item.state = 1
            } else {
                item.state = 0
                let newArr = this.tagArr.filter(itemm => itemm !== item.tag); // 过滤掉等于3的元素  
                this.tagArr = newArr
            }
        },
        // 重新上传音频
        reupload() {
            this.aPath = ''
        },
        // 选中某条克隆音频
        selectAudio(item, index) {
            this.selectAudioIndex = index
            console.log(item)
        },
        // 刷新试听音频
        refreshAudition(item, index) {
            let that = this
            if (that.time) {
                clearTimeout(that.time)
            }
            const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });

            that.time = setTimeout(function () {
                let data = {
                    id: item.id
                }
                refreshAudition(data).then(res => {
                    if (res.data.code == 10000) {
                        loading.close();
                        that.$message({
                            message: '刷新成功',
                            type: 'success'
                        });
                        that.auditionList[index] = res.data.data
                        that.key += 1
                        // that.$forceUpdate()
                    } else {
                        loading.close();
                    }

                })
                that.time = null;
            }, 500)
        },
        // 合成试听 
        syntheticAudition() {
            let that = this
            if (that.time) {
                clearTimeout(that.time)
            }
            that.time = setTimeout(function () {
                if (that.clonesNumber == 0) return that.$message.error('合成次数已用尽，请提交完成合成后再试！');
                if (that.cloneName == '') return that.$message.error('请输入声音名称');
                if (that.tagArr.length == 0) return that.$message.error('请选择个性标签');
                if (that.aPath == '') return that.$message.error('请上传音频');
                const loading = that.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });

                let data = {
                    name: that.cloneName,
                    gender: that.radio,
                    tag: that.tagArr,
                    source_url: that.aPath
                }
                syntheticAudition(data).then(res => {
                    if (res.data.code == 10000) {
                        loading.close();
                        that.$message({
                            message: '上传成功',
                            type: 'success'
                        });
                        that.getAvailable()
                    } else {
                        loading.close();
                    }
                })
                that.time = null;
            }, 1000)
        },
        // 最终确认提交克隆音频
        confirmSubmission() {
            let that = this
            if (that.time) {
                clearTimeout(that.time)
            }
            that.time = setTimeout(function () {
                let list = that.auditionList
                let index = that.selectAudioIndex
                if (that.signinChecked == false) return that.$message.error('请阅读并同意克隆协议');
                if (index == '-1') return that.$message.error('请选择克隆音频');
                if (list[index].listen_url == '') return that.$message.error('音频克隆尚未成功');
                const loading = that.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });

                let data = {
                    id: list[index].id
                }
                confirmAudio(data).then(res => {
                    if (res.data.code == 10000) {
                        loading.close();
                        that.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                        that.$router.go(-1)
                    } else {
                        loading.close();
                    }
                })
                that.time = null;
            }, 1000)
        },
        // 取消回退上一页
        cancel() {
            this.$router.go(-1)
        },
        // 获取公共标签
        getTagList() {
            getTagList().then(res => {
                if (res.data.code == 10000) {
                    let list = res.data.data
                    for (let i = 0; i < list.length; i++) {
                        list[i].state = 0
                        this.tagList.push(list[i])
                    }
                }
            })
        },
        // 获取克隆次数
        getAvailable() {

            getAvailable().then(res => {
                if (res.data.code == 10000) {
                    this.clonesNumber = res.data.data.count
                    if (res.data.data.count !== 3) {
                        this.aPath = res.data.data.tryout[0].source_url
                    }
                    this.auditionList = res.data.data.tryout
                }
            })
        },


        openAgreement() {
            this.agreementShow = true
        },
        // 同意协议
        consentAgreement() {
            this.signinChecked = true
            this.agreementShow = false
        },





    },
}
</script>
<style lang="less" scoped>
.content {
    .header {
        padding: 0 20px;
        height: 50px;
        background: rgba(146, 186, 255, 0.4);
        line-height: 50px;

        .title {
            cursor: pointer;
            font-weight: bold;

            i {
                margin-right: 10px;
                font-weight: bold;
                color: #000;
            }
        }
    }

    .cloneBox {
        margin: 20px 25px 20px 25px;
        padding: 0 15px 0 15px;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        height: 685px;

        .cloneBox_left {
            border-right: 1px solid #D8D8D8;
            width: 60%;

            .Boxtitle {
                color: #3D3D3D;
                line-height: 50px;

            }

            .cloneName {
                .title {
                    margin-bottom: 10px;
                    font-size: 24px;
                    color: #000000;
                }

                span {
                    color: red;
                }
            }

            .selectTag {
                margin-top: 20px;

                .title {
                    margin-bottom: 10px;
                    font-size: 24px;
                    color: #000000;

                    span {
                        color: red;
                    }
                }

                .itemBox {
                    display: flex;
                    flex-wrap: wrap;

                    .item {
                        cursor: pointer;
                        /*悬停变小手的属性*/
                        margin: 5px;
                        padding: 2px;
                        font-size: 18px;
                        color: #3D3D3D;
                        background-color: #EDEEF7;
                    }

                    .active {
                        background-color: #BAE9FF;
                    }
                }
            }

            .timbre {
                margin-top: 10px;
                display: flex;
                margin-right: 20px;
                flex: 1;
                line-height: 53px;

                .title {
                    margin-bottom: 10px;
                    font-size: 24px;
                    color: #000000;
                }

                .radio {
                    margin-left: 20px
                }
            }

            .audioFile {
                margin-top: 10px;
                padding-bottom: 20px;

                .audioFile_box {
                    display: flex;

                    .right {
                        margin-left: 30px;
                        margin-top: 50px;

                        .auditionListClick {
                            border: 1px solid #3E7AFE;
                            margin-bottom: 10px;
                            display: flex;
                            background-color: #F1F3F4;
                            padding: 5px 10px;
                            border-radius: 5px;

                            img {
                                margin-left: 15px;
                                margin-top: 12px;
                            }
                        }

                        .auditionList {
                            margin-bottom: 10px;
                            display: flex;
                            background-color: #F1F3F4;
                            padding: 5px 10px;
                            border-radius: 5px;

                            img {
                                margin-left: 15px;
                                margin-top: 12px;
                            }
                        }
                    }
                }

                .title {
                    margin-bottom: 10px;
                    font-size: 24px;
                    color: #000000;

                    span {
                        color: red;
                    }
                }

                .playBox {
                    width: 517px;
                    height: 141px;
                    border-radius: 5px;
                    border: 1px solid #CCCACA;
                    margin-top: 20px;
                    padding-top: 20px;

                    .playModel {
                        display: flex;
                        justify-content: center;

                    }

                    .anew_craft {
                        display: flex;
                        justify-content: space-between;
                        margin: 15px 20px 0 20px;

                        .anew {
                            height: 42px;
                            width: 150px;
                            line-height: 42px;
                            margin-top: 10px;
                            background: #3E7AFE;
                            color: #fff;
                            border-radius: 5px;
                            cursor: pointer;
                            /*悬停变小手的属性*/
                            font-size: 16px;
                            text-align: center;
                            margin-right: 10px;
                        }

                        .syntheticAudition {
                            padding-bottom: 20px;
                            display: flex;

                            .saBut {
                                height: 42px;
                                width: 150px;
                                line-height: 42px;
                                margin-top: 10px;
                                background: #3E7AFE;
                                color: #fff;
                                border-radius: 5px;
                                cursor: pointer;
                                /*悬停变小手的属性*/
                                font-size: 16px;
                                text-align: center;
                            }

                            span {
                                color: red;
                            }
                        }
                    }


                }

                .referenceText {
                    .r_title {
                        font-size: 12px;
                        color: #838383;
                        line-height: 30px;

                        span {
                            color: #3977F3;
                            cursor: pointer;
                            /*悬停变小手的属性*/
                        }
                    }
                }
            }
        }

        .cloneBox_right {
            width: 40%;

            .right_title {
                color: #3D3D3D;
                line-height: 50px;
                padding: 0 20px;
            }

            .right_box {
                padding: 0 100px;

                .recordedText {
                    margin-top: 20px;
                    color: #3D3D3D;

                    .title {
                        line-height: 50px;
                    }

                    .text {
                        line-height: 20px;
                        font-size: 12px;
                    }
                }
            }




        }
    }

    .submit_box {
        position: relative;
        left: 0;
        bottom: 0;
        height: 80px;
        background: #FFFFFF;
        width: 100%;
        display: flex;

        .submit_cancel {
            display: flex;
            height: 100%;

            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 30px;
            }
        }

        .agreement {
            margin-left: 100px;
            line-height: 80px;
            color: #3D3D3D;
        }

        span {
            font-size: 14px;
            color: #3977F3;
            cursor: pointer;
        }
    }


}

/deep/.agreementShow {
    .text {
        overflow-y: auto;
        /* 出现垂直滚动条 */
        height: 500px;
    }

    border-radius: 10px;
}

/deep/.agreementShow::-webkit-scrollbar {
    .text::-webkit-scrollbar {
        display: none;
    }
}
</style>